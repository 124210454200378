<template>
  <div
    class="text-center"
    align-v="center"
  >
    <b-overlay
      :show="data.showLoader"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
    >
      <div class="text-left">
        <b-card
          v-if="!data.showLoader"
          class="action-tabs"
        >
          <b-tabs
            pills
          >
            <b-tab
              title="Take Action"
              active
              @click="showTakeActionContent"
            />
            <b-tab
              title="Actioned"
              @click="showActionedContent"
            />
          </b-tabs>
        </b-card>
        <b-card-group
          v-if="!data.showLoader && data.showTakeAction"
          deck
        >
          <template v-for="action in data.actions">
            <actions-card
              :key="action.id"
              ref="data.actions"
              :data="action"
              @show-details-modal="showDetailsModal"
              @refresh="refreshActions()"
            />
          </template>
        </b-card-group>
        <b-card-group
          v-if="!data.showLoader && !data.showTakeAction"
          deck
        >
          <template v-for="action in data.actioned">
            <actions-card
              :key="action.id"
              ref="data.actioned"
              :data="action"
              @show-details-modal="showDetailsModal"
            />
          </template>

        </b-card-group>
        <b-modal
          id="details-modal"
          header-bg-variant="modal-header-dark-blue"
          modal-class="modal-primary"
          size="lg"
          scrollable
          centered
          :title="data.selectedActionItemData.name"
        >
          <div v-html-safe="data.selectedActionItemData.detailsHtml" />
          <template #modal-footer>
            <div class="w-200">
              <b-button
                v-if="data.selectedActionItemData.showDetailsButton"
                :disabled="data.selectedActionItemData.showDetailsSpinner"
                variant="primary"
                class="float-right"
                align-v="center"
                @click="detailsButtonClicked"
              >
                <span
                  v-if="data.selectedActionItemData.showDetailsSpinner"
                  class="spinner-border spinner-border-sm spinner-in-button"
                  role="status"
                  aria-hidden="true"
                />
                {{ data.selectedActionItemData.detailsButton }}
              </b-button>
            </div>
          </template>
        </b-modal>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BCardGroup, BTab, BTabs, BOverlay, BModal, BButton,
} from 'bootstrap-vue'
import axios from '@axios'
import ActionsCard from './ActionsCard.vue'

export default {
  components: {
    BCard,
    BCardGroup,
    BTab,
    BTabs,
    BOverlay,
    ActionsCard,
    BModal,
    BButton,
  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    this.data = {
      showLoader: true,
      showTakeAction: true,
      actions: [],
      actioned: [],
      selectedActionItemData: {},
    }
    this.getActions()
  },
  methods: {
    showDetailsModal(itemData) {
      this.data.selectedActionItemData = itemData
      this.data.selectedActionItemData.showDetailsSpinner = false
      this.$bvModal.show('details-modal')
    },
    showTakeActionContent() {
      this.data.showTakeAction = true
    },
    showActionedContent() {
      this.data.showTakeAction = false
    },
    refreshActions() {
      this.data.showLoader = true
      this.data.selectedActionItemData = {}
      this.getActions()
    },
    getActions() {
      axios
        .get('/action')
        .then(response => {
          console.log(response)
          const { options, actioned } = response.data
          this.data.actioned = []
          this.data.actions = []
          const inProgress = []
          const toDo = []
          const nudgesToDo = []
          const nudgesInProgress = []
          let oneSurveyInProgress = false
          options.forEach(item => {
            const actionItem = JSON.parse(JSON.stringify(item))
            if (!Object.prototype.hasOwnProperty.call(actionItem, 'type')) {
              actionItem.type = 'Survey'
            }
            if (actionItem.type === 'Survey'
              && !actioned.includes(`${actionItem.id}_Skipped`)
              && !actioned.includes(`${actionItem.id}_Completed`)
              && actioned.includes(actionItem.id)) { // in progress
              oneSurveyInProgress = true
            }
          })
          options.forEach(item => {
            const actionItem = JSON.parse(JSON.stringify(item))
            if (!Object.prototype.hasOwnProperty.call(actionItem, 'type')) {
              actionItem.type = 'Survey'
            }
            if (!actioned.includes(`${actionItem.id}_Skipped`)) { // Action skipped
              if (actioned.includes(`${actionItem.id}_Completed`)) { // Action done
                actionItem.inProgress = false
                actionItem.buttonEnabled = false
                if (actionItem.type === 'Nudge') {
                  // actionItem.buttonEnabled = true
                  actionItem.detailsButton = ''
                  actionItem.showDetailsButton = false
                }
                this.data.actioned.push(actionItem)
              } else if (actioned.includes(actionItem.id)) { // action in progress
                actionItem.inProgress = true
                actionItem.buttonEnabled = false
                if (actionItem.type === 'Nudge') {
                  actionItem.detailsButton = 'Set to Completed'
                  actionItem.showDetailsButton = true
                  nudgesInProgress.push(actionItem)
                } else {
                  inProgress.push(actionItem)
                }
              } else { // action in options
                actionItem.inProgress = false
                actionItem.buttonEnabled = true
                if (Object.prototype.hasOwnProperty.call(actionItem, 'requires') && !actioned.includes(actionItem.requires)) {
                  actionItem.buttonEnabled = false
                }
                if (actionItem.type === 'Nudge') {
                  actionItem.detailsButton = 'Add to "In Progress" Actions'
                  actionItem.showDetailsButton = true
                  nudgesToDo.push(actionItem)
                } else {
                  if (actionItem.buttonEnabled === true && oneSurveyInProgress) {
                    actionItem.buttonEnabled = false
                  }
                  toDo.push(actionItem)
                }
              }
            }
          })
          nudgesToDo.reverse() // so that the most recent nudges are first
          this.data.actions = nudgesInProgress.concat(inProgress, nudgesToDo, toDo)
          this.data.showLoader = false
        })
        .catch(error => {
          console.log(error)
        })
    },

    setInProgress() {
      this.data.selectedActionItemData.showDetailsSpinner = true
      axios
        .put(`/action/${this.data.selectedActionItemData.id}/trigger`, { action: this.data.selectedActionItemData.id })
        .then(response => {
          console.log(response)
          this.data.selectedActionItemData.showDetailsSpinner = false
          this.$bvModal.hide('details-modal')
          this.refreshActions()
        })
        .catch(error => {
          console.log(error)
        })
    },
    setToCompleted() {
      this.data.selectedActionItemData.showDetailsSpinner = true
      axios
        .put(`/action/${this.data.selectedActionItemData.id}/complete`, { action: this.data.selectedActionItemData.id })
        .then(response => {
          console.log(response)
          this.data.selectedActionItemData.showDetailsSpinner = false
          this.$bvModal.hide('details-modal')
          this.refreshActions()
        })
        .catch(error => {
          console.log(error)
        })
    },
    detailsButtonClicked() {
      if (this.data.selectedActionItemData.detailsButton === 'Set to Completed') {
        this.setToCompleted()
      } else {
        this.setInProgress()
      }
    },
  },
}
</script>

<style>

</style>
