<template>
  <div>
    <b-card
      :title="data.name"
      :img-src="require(`@/assets/images/${data.image}`)"
      img-alt="Image"
      img-top
      tag="action"
      style="max-width: 20rem; min-width: 20rem;"
      class="mb-2"
    >
      <b-card-text class="badge-pills">
        <b-badge
          v-if="data.tags.includes('B')"
          pill
          variant="green"
        >
          Belongingness
        </b-badge>
        <b-badge
          v-if="data.tags.includes('P')"
          pill
          variant="pink"
        >
          Psychological Safety
        </b-badge>
        <b-badge
          v-if="data.tags.includes('O')"
          pill
          variant="blue"
        >
          Organisational Trust
        </b-badge>
        <b-badge
          v-if="data.tags.includes('E')"
          pill
          variant="orange"
        >
          Engagement
        </b-badge>
        <b-badge
          v-if="data.tags.includes('D')"
          pill
          variant="purple"
        >
          Diversity Climate
        </b-badge>

      </b-card-text>
      <b-card-text>
        {{ data.text }}
      </b-card-text>
      <hr>
      <b-button
        v-if="data.type === 'Survey'"
        :disabled="!data.buttonEnabled"
        href="#"
        variant="primary"
        @click="confirmAction(data.id, data.buttonText)"
      >
        {{ data.buttonText }}
      </b-button>
      <b-button
        v-if="data.type === 'Nudge'"
        variant="primary"
        @click="showNudgeDetails(data)"
      >
        {{ data.buttonText }}
      </b-button>
      <b-button
        v-if="data.inProgress"
        size="xsm"
        pill
        :disabled="true"
        href="#"
        variant="outline-secondary"
      >
        In Progress
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardText, BBadge, BButton,
} from 'bootstrap-vue'
import axios from '@axios'
import flatpickr from 'flatpickr'
import 'flatpickr/dist/themes/dark.css'

let flatpickrInstance

export default {
  components: {
    BCard,
    BCardText,
    BBadge,
    BButton,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    showNudgeDetails(itemData) {
      this.$emit('show-details-modal', itemData)
    },
    getDefaultEndDate() {
      const today = new Date()
      return new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 14,
        17,
        0,
        0,
        0,
      )
    },
    confirmAction(id, action) {
      this.$swal({
        title: `${action}?`,
        html: '<div style="margin:10px">Pick an completion date and time:</div><input class="swal2-input" id="expiry-date">',
        icon: 'info',
        showCancelButton: true,
        stopKeydownPropagation: false,
        confirmButtonText: 'Sounds good!',
        cancelButtonText: 'Maybe later...',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        showLoaderOnConfirm: true,
        buttonsStyling: false,
        preConfirm: (() => {
          console.log()
          if (flatpickrInstance.selectedDates[0] < new Date()) {
            this.$swal.showValidationMessage('The suvey completion date can\'t be in the past')
            return false
          }
          return axios
            .put(`/action/${id}/trigger`, { action: id, endDate: flatpickrInstance.selectedDates[0].getTime() })
            .then(response => {
              console.log(response)
              this.$swal({
                title: 'Success!',
                text: `Thank you for activating: ${action} for all employees!`,
                icon: 'success',
                allowOutsideClick: true,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              this.$emit('refresh')
            })
            .catch(error => {
              console.log(error)
              this.$swal({
                title: 'Error!',
                text: ' Oops! Looks like something didnt go to plan, Sorry about that. Please try again, or contact Support',
                icon: 'error',
                allowOutsideClick: true,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }),
        willOpen: () => {
          console.log(this.$swal.getPopup())
          flatpickrInstance = flatpickr(
            this.$swal.getPopup().querySelector('#expiry-date'),
            {
              enableTime: true,
              dateFormat: 'l, d F Y, h:i K',
              defaultDate: this.getDefaultEndDate(),
              minDate: 'today',
              position: 'above center',
            },
          )
          console.log(flatpickrInstance)
        },
      })
    },
  },
}
</script>

<style>

</style>
